<template>
  <section id="tasks">
    <div class="listHeader">
      <h1 class="pl-3 homeHeader">My Tasks</h1>
    </div>
    <div class="hr pl-2">
      <router-link to="/" class="text-grey text-xs">&#60; BACK</router-link>
    </div>
    <div class="task-list relative w-full rounded p-4 grey mt-5 text-white">
      <h4 class="pl-2 text-xs font-bold">TASKS</h4>
      <p class="hr pl-2 pt-1 pb-2 text-white">
        <a class="float-left pr-2"><img src="@/assets/img/checkmark_circle.svg" alt=""/></a
        ><span class="line-through text-grey"
          >Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span
        >
      </p>
      <p class="hr pl-2 pt-1 pb-2 text-white">
        <a class="float-left pr-2"><img src="@/assets/img/checkmark_emptycircle.svg" alt=""/></a
        >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </p>
      <p class="hr pl-2 pt-1 pb-2 text-white">
        <a class="float-left pr-2"><img src="@/assets/img/checkmark_emptycircle.svg" alt=""/></a
        >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </p>
      <p class="hr pl-2 pt-1 pb-2 text-white">
        <a class="float-left pr-2"><img src="@/assets/img/checkmark_emptycircle.svg" alt=""/></a
        >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </p>
      <div class="mt-12">
        <router-link to="/assign-task" tag="button" class="edit-btn px-10 text-center"
          >Create New Task</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    components: {},
    data() {
      return {};
    },
    mounted() {},
    computed: {
      tasks: function() {
        return this.$store.getters.tasks;
      },
    },
    watch: {},
    methods: {},
  };
</script>
<style lang="scss" scope>
  #tasks {
    .hr {
      .text-grey {
        color: #979797;
        @media (hover: hover) {
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  // @import "tailwindcss/base";
  // @import "tailwindcss/custom-forms";
</style>
